<template>
  <div v-loading="isLoading" class="p-3">
    <div class="p-2 flex flex-col md:flex-row">
      <div class="fs-32 font-bold text-black text-center flex-1">{{ $t("Thu ngân") }}</div>
      <el-button
        @click="handleShowModalCreateReceipt"
        class="mb-2"
        type="primary"
      >{{ $t("Tạo phiếu thu") }}</el-button>
    </div>

    <div class="p-2 flex flex-col">
      <FilterPayment @onChangeFilter="handleChangeFilters" />
      <div class="flex gap-2 my-2 items-center">
        <el-card shadow="always" class="flex-1 fs-22 text-center flex flex-col gap-4 cs-card">
          <div class="fs-22 text-center">{{ $t("Doanh thu") }}</div>
          <div class="font-bold fs-28 mt-2">{{ overviewData.total_revenue }}</div>
        </el-card>
        <el-card shadow="always" class="flex-1 fs-22 text-center flex flex-col gap-4 cs-card">
          <div class="fs-22 text-center">{{ $t("Dịch vụ") }}</div>
          <div class="font-bold fs-28 mt-2">{{ overviewData.total_services }}</div>
        </el-card>
        <el-card shadow="always" class="flex-1 fs-22 text-center flex flex-col gap-4 cs-card">
          <div class="fs-22 text-center">{{ $t("Bệnh nhân") }}</div>
          <div class="font-bold fs-28 mt-2">{{ overviewData.total_customers }}</div>
        </el-card>
      </div>
    </div>
    <div class="h-full flex flex-col xl:flex-row gap-3 overflow-x-scroll p-2">
      <div class="bg-white p-2 flex-1 flex flex-col" style="width: 65%">
        <el-table
          ref="paymentReceiptTable"
          @selection-change="handleSelectionChange"
          class="cs-table"
          :data="tableData"
          style="width: 100%"
          :row-key="getRowKey"
          row-class-name="cursor-pointer"
        >
          <el-table-column
            :selectable="handleCheckShowSelect"
            reserve-selection
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column prop="number_code" label="Mã Phiếu thu"></el-table-column>

          <!-- <el-table-column prop="name" label="Tên Phiếu thu"> </el-table-column> -->
          <el-table-column prop="person_name" label="Tên Bệnh nhân"></el-table-column>
          <el-table-column :formatter="getDateTime" prop="name" label="Ngày lập"></el-table-column>
          <el-table-column prop="number_code" :formatter="getTotalAmount" label="Giá tiền"></el-table-column>
          <!-- <el-table-column prop="created_by.name" label="Người lập">
          </el-table-column>-->

          <!-- <el-table-column prop="name" label="Tên chỉ định"> </el-table-column> -->
          <el-table-column prop="status" label="Trạng thái">
            <template slot-scope="scope">
              <span
                :class="
                  checkPaidReceipt(scope.row) ? 'text-success' : 'text-danger'
                "
              >
                {{
                checkPaidReceipt(scope.row)
                ? "Đã thanh toán"
                : "Chưa thanh toán"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <div class="flex items-center gap-2">
                <div
                  @click="handleSendMessage(scope.row)"
                  v-b-tooltip.hover.top
                  title="Gửi phiếu thu cho bệnh nhân"
                >
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" fill="white" />
                    <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" stroke="#20419B" />
                    <path
                      d="M20 6L17.55 13L15.1 20L12.125 13.875L6 10.9L20 6Z"
                      stroke="#20419B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20 6L12 14"
                      stroke="#20419B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <svg
                  @click="handleViewDetail(scope.row)"
                  class="cursor-pointer"
                  width="30"
                  height="26"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="#20409B" />
                  <mask
                    id="mask0_35008_774"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="6"
                    y="6"
                    width="18"
                    height="18"
                  >
                    <rect
                      x="6.92859"
                      y="6.92871"
                      width="16.1429"
                      height="16.1429"
                      fill="#D9D9D9"
                      stroke="#20409B"
                    />
                  </mask>
                  <g mask="url(#mask0_35008_774)">
                    <path
                      d="M15 17.8701C15.8962 17.8701 16.6583 17.5563 17.2857 16.9289C17.913 16.3015 18.2268 15.5394 18.2268 14.6433C18.2268 13.7471 17.913 12.985 17.2857 12.3577C16.6583 11.7303 15.8962 11.4165 15 11.4165C14.1039 11.4165 13.3418 11.7303 12.7144 12.3577C12.087 12.985 11.7732 13.7471 11.7732 14.6433C11.7732 15.5394 12.087 16.3015 12.7144 16.9289C13.3418 17.5563 14.1039 17.8701 15 17.8701ZM15 16.5593C14.4676 16.5593 14.0154 16.3731 13.6428 16.0005C13.2702 15.6279 13.084 15.1757 13.084 14.6433C13.084 14.1108 13.2702 13.6587 13.6428 13.286C14.0154 12.9134 14.4676 12.7272 15 12.7272C15.5325 12.7272 15.9846 12.9134 16.3573 13.286C16.7299 13.6587 16.9161 14.1108 16.9161 14.6433C16.9161 15.1757 16.7299 15.6279 16.3573 16.0005C15.9846 16.3731 15.5325 16.5593 15 16.5593ZM15 19.9879C13.2644 19.9879 11.6835 19.5035 10.257 18.5347C8.83193 17.5668 7.7984 16.2698 7.15632 14.6433C7.7984 13.0168 8.83193 11.7197 10.257 10.7518C11.6835 9.78301 13.2644 9.29863 15 9.29863C16.7356 9.29863 18.3165 9.78301 19.743 10.7518C21.1681 11.7197 22.2016 13.0168 22.8437 14.6433C22.2016 16.2698 21.1681 17.5668 19.743 18.5347C18.3165 19.5035 16.7356 19.9879 15 19.9879Z"
                      fill="#20409B"
                      stroke="#20409B"
                      stroke-width="0.025"
                    />
                  </g>
                </svg>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <AppPagination
          :paginationProps="pagination"
          @onChangePagination="getServiceTransactionSlipWS"
        />
      </div>
      <div class="bg-white p-2" style="width: 32%">
        <div class="flex justify-between gap-2 items-center cs-border">
          <div class="fs-18 font-bold">
            <span class>{{ $t("Tổng tiền:") }}</span>
            <span class="text-blue-900 ml-2">{{ totalAmount.label }}</span>
          </div>
          <div>
            <el-button
              round
              plain
              class="flex items-center"
              type="primary"
              @click="handleOpenModal"
              :disabled="!receiptSelected.length"
            >
              <span class="fs-16">{{ "Thanh toán" }}</span>
              <!-- <i class="el-icon-s-promotion fs-18 ml-1"></i> -->
            </el-button>
          </div>
        </div>

        <el-table
          @selection-change="handleSelectionChange"
          class="cs-table"
          :data="receiptSelected"
          style="width: 100%"
        >
          <el-table-column prop="number_code" label="Mã Phiếu thu"></el-table-column>
          <el-table-column prop="person_name" label="Tên Bệnh nhân"></el-table-column>
          <el-table-column fixed="right" label width="60">
            <template slot-scope="scope">
              <i
                @click="handleRemoveIndication(scope.row)"
                class="el-icon-delete text-danger cursor-pointer fs-18"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <ModalPaymentReceipt
      ref="ModalPaymentReceipt"
      :receiptSelected="receiptSelected"
      :totalAmount="totalAmount"
      @onSuccess="handlePaymentSuccess()"
    />
    <ModalCreateReceipt ref="ModalCreateReceipt" @onRefresh="handleCreateReceiptSuccess" />
  </div>
</template>

<script>
import FilterPayment from './FilterPayment.vue'
import { AppPagination } from '@/components/Common'
import { TRANSACTION_SLIP_STATUS } from '@/utils/constants.js'
import appUtils from '@/utils/appUtils.js'
import { mixinQueryParams } from '@/utils/mixinQueryParams.js'
import ModalPaymentReceipt from './ModalPaymentReceipt.vue'
import ModalCreateReceipt from './ModalCreateReceipt.vue'

export default {
  name: 'PatientVisitResult',
  components: {
    FilterPayment,
    AppPagination,
    ModalPaymentReceipt,
    ModalCreateReceipt
  },
  mixins: [mixinQueryParams],
  data () {
    return {
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      receiptSelected: [],
      isLoading: false,
      TRANSACTION_SLIP_STATUS,
      filterParams: {
        from_date: window
          .moment()
          .startOf('day')
          .unix(),
        to_date: window
          .moment()
          .endOf('day')
          .unix()
      },
      revenueTotal: 0,
      appUtils,
      overviewData: {
        total_revenue: 0,
        total_customers: 0,
        total_services: 0
      },
      countDown: 0,
      lastSlipId: 0
    }
  },
  created () {
    this.getServiceTransactionSlipWS()
    this.getOverviewPayment({
      from_date: window
        .moment()
        .startOf('day')
        .unix(),
      to_date: window
        .moment()
        .endOf('day')
        .unix()
    })
  },
  mounted () {},
  computed: {
    totalAmount () {
      const totalPrice =
        this.receiptSelected.reduce((total, item) => total + item.amount, 0) ||
        0

      return {
        label: appUtils.numberFormat(totalPrice, 'VND'),
        value: totalPrice
      }
    }
  },
  methods: {
    async getServiceTransactionSlipWS (data) {
      if (!this.$globalClinic?.id) return

      try {
        this.isLoading = true
        const params = {
          order: 'desc',
          sort_by: 'created_at',
          page_num: data?.currentPage || this.pagination.currentPage,
          page_size: data?.pageSize || this.pagination.pageSize,
          ws_id: this.$globalClinic?.id,
          date_revenue: window.moment().unix(),
          ...(this.filterParams || {})
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getServiceTransactionSlipWS(params)
        const responseData = response.data?.data || []
        this.revenueTotal = response.data.revenue_total || 0

        this.tableData = responseData.map((item, index) => ({
          index,
          ...item
        }))
        this.pagination = {
          currentPage: response.data?.page?.page_num,
          totalItems: response.data?.page?.total,
          pageSize: response.data?.page?.page_size
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSelectionChange (data) {
      this.receiptSelected = data
    },
    handleRemoveIndication (item) {
      this.receiptSelected.filter(indication => indication.id !== item.id)

      this.$refs.paymentReceiptTable.toggleRowSelection(item, false)
    },

    getDateTime (row) {
      return row.created_at
        ? window.moment(row.created_at).format('HH:mm DD/MM/YYYY')
        : ''
    },
    handleChangeFilters (data) {
      this.handleSetFilter(data)
      this.filterParams = data || {}

      this.getServiceTransactionSlipWS(
        {
          currentPage: 1,
          pageSize: 10
        },
        data
      )

      this.getServiceTransactionSlipWS(data)
      this.getOverviewPayment(data)
    },
    checkPaidReceipt (data) {
      return (
        data.service_payment_transaction &&
        data.service_payment_transaction.status === TRANSACTION_SLIP_STATUS.paid
      )
    },
    getTotalAmount (row) {
      return appUtils.numberFormat(row.amount, 'VND')
    },
    handleViewDetail (row) {
      this.$router
        .push({
          name: 'IndicationReceiptDetail',
          params: {
            id: row.id
          },
          query: {
            type: 'payment-management'
          }
        })
        .catch(() => {})
    },
    async handleOpenModal () {
      const totalPatient = this.receiptSelected.reduce((total, item) => {
        if (total.find(totalItem => totalItem.person_id === item.person_id)) {
          return total
        }

        total.push(item)

        return total
      }, [])

      if (totalPatient.length > 1) {
        const isConfirm = await this.$confirm(
          this.$t(
            `Bạn có chắc chắn muốn thanh toán phiếu thu cho <strong style='font-size:18px;color:#20409B'>${totalPatient.length}</strong> bệnh nhân không?`
          ),
          this.$t('lbl_confirm'),
          {
            cancelButtonText: this.$t('lbl_cancel'),
            confirmButtonText: this.$t('lbl_confirm'),
            dangerouslyUseHTMLString: true
          }
        ).catch(() => {})

        if (!isConfirm) return
      }

      this.$refs.ModalPaymentReceipt.handleOpen(
        this.receiptSelected,
        this.totalAmount
      )
    },
    handlePaymentSuccess () {
      this.receiptSelected = []
      this.$refs.paymentReceiptTable.clearSelection()
      this.getServiceTransactionSlipWS()
    },
    handleCheckShowSelect (row) {
      if (row.service_payment_transaction?.status === 2) return false

      return true
    },
    handleChangeSelect (row) {
      if (row.service_payment_transaction?.status === 2) return
      this.$refs.paymentReceiptTable.toggleRowSelection(row)
    },
    handleCreateReceiptSuccess () {
      this.getServiceTransactionSlipWS({
        currentPage: 1,
        pageSize: 10
      })
    },
    handleShowModalCreateReceipt () {
      this.$refs.ModalCreateReceipt.handleOpen()
    },
    getRowKey (row) {
      return `${row.id}-${row.index}`
    },
    async getOverviewPayment (data = {}) {
      if (!this.$globalClinic?.id) return

      try {
        const params = {
          ws_id: this.$globalClinic?.id,
          ...(this.filterParams || {}),
          ...data
        }

        if (!params.from_date || !params.to_date) {
          this.overviewData = {
            total_revenue: 0,
            total_customers: 0,
            total_services: 0
          }
          return
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getOverviewPayment(params)
        const responseData = response.data || {}

        const total_revenue = this.formatCurrency(
          responseData.total_revenue || 0
        )
        const total_customers = appUtils.numberFormat(
          responseData.total_customers
        )
        const total_services = appUtils.numberFormat(
          responseData.total_services
        )

        this.overviewData = {
          total_revenue,
          total_customers,
          total_services
        }
      } catch (error) {
        console.log(error)
      }
    },
    formatCurrency (amount) {
      return appUtils.numberFormat(amount || 0, 'VND')
      // if (amount >= 1000000000) {
      //   return (amount / 1000000000).toFixed(2) + 'B'
      // } else if (amount >= 1000000) {
      //   return (amount / 1000000).toFixed(2) + 'M'
      // } else {
      //   return appUtils.numberFormat(amount || 0, 'VND')
      // }
    },
    async handleSendMessage (data) {
      if (this.countDown > 0 && data.id === this.lastSlipId) {
        this.$toast.open({
          message:
            'Bạn chưa thể gửi phiếu thu này cho bệnh nhân, vui lòng chờ 30 giây',
          type: 'error'
        })
      } else {
        const r = await this.$confirm(
          `Bạn có chắc chắn muốn gửi phiếu thu cho bệnh nhân <strong>${data?.person_name ||
            ''}</strong> không?`,
          'Xác nhận gửi phiếu thu cho bệnh nhân',
          {
            dangerouslyUseHTMLString: true,
            cancelButtonText: this.$t('lbl_cancel'),
            confirmButtonText: this.$t('lbl_confirm')
          }
        ).catch(() => {})

        if (!r) return

        if (!data) {
          this.$toast.open({
            message: 'Không tìm thấy thông tin bệnh nhân',
            type: 'error'
          })
          return
        }

        try {
          const params = {
            slip_id: data?.id,
            person_id: data?.person_id
          }
          const response = await this.$rf
            .getRequest('DoctorRequest')
            .sendReceiptToPatient(params)
          if (response.status === 200) {
            this.$toast.open({
              message: 'Gửi phiếu thu đến bệnh nhân thành công',
              type: 'success'
            })
            this.handleSetCountDown()
            this.getServiceTransactionSlipWS()
          } else {
            this.$toast.open({
              message: 'Gửi phiếu thu đến bệnh nhân thất bại',
              type: 'error'
            })
          }
          this.lastSlipId = data.id
        } catch (error) {
          console.log(error)
          this.$toast.open({
            message: 'Gửi phiếu thu đến bệnh nhân thất bại',
            type: 'error'
          })
        }
      }
    },
    handleSetCountDown () {
      const self = this
      this.countDown = 30

      let intervalMs

      intervalMs = setInterval(() => {
        self.countDown = self.countDown - 1

        if (self.countDown <= 0) {
          clearInterval(intervalMs)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-table * {
    color: black !important;
  }

  .text-success {
    color: #5eba00 !important;
  }

  .text-info {
    color: #4299e1 !important;
  }

  .text-warning {
    color: #ed8936 !important;
  }

  .text-danger {
    color: #e53e3e !important;
  }

  .cs-border {
    border-bottom: 1px solid #dedede !important;
    padding-bottom: 16px;
  }

  @media (max-width: '1150px') {
    .hodo-el {
      .filter-item {
        width: 100% !important;

        .el-select {
          width: 100% !important;
        }
      }
    }
  }

  .cs-card {
    border-radius: 10px;
    .el-card__body {
      padding: 12px;
    }
  }
}
</style>