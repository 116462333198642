<template>
  <div class="flex flex-col lg:flex-row items-center gap-2 hodo-el mb-2">
    <div class="flex-1 filter-item">
      <small>{{ $t("Tìm kiếm") }}</small>
      <el-input
        placeholder="Nhập tên BN, SĐT, Mã phiếu thu..."
        v-model="searchValue"
        @input="handleDebounce"
        clearable
      ></el-input>
    </div>
    <div class="filter-item" style="min-width: 300px">
      <small>{{ $t("Trạng thái thanh toán") }}</small>
      <div>
        <el-select
          class="w-full"
          v-model="payment_status"
          @input="handleDebounce"
          clearable
          placeholder="Chọn trạng thái thanh toán"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="filter-item">
      <small>{{ $t("Từ ngày") }}</small>
      <div>
        <el-date-picker
          @input="handleDebounce"
          v-model="from_date"
          type="date"
          placeholder="Từ ngày"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="filter-item">
      <small>{{ $t("Đến ngày") }}</small>
      <div>
        <el-date-picker
          @input="handleDebounce"
          v-model="to_date"
          type="date"
          placeholder="Đến ngày"
        >
        </el-date-picker>
      </div>
    </div>
  </div>
</template>
  
<script>
import { debounce, isEmpty } from 'lodash'

export default {
  name: 'FilterPayment',
  data () {
    return {
      searchValue: '',
      statusOptions: [
        {
          value: 1,
          label: 'Chưa thanh toán'
        },
        {
          value: 2,
          label: 'Đã thanh toán'
        }
      ],
      payment_status: null,
      to_date: window.moment().endOf('day').format('YYYY-MM-DD HH:mm'),
      from_date: window.moment().startOf('day').format('YYYY-MM-DD HH:mm')
    }
  },
  created () {
    this.handleMapFilter()
  },
  methods: {
    handleDebounce: debounce(function () {
      this.$emit('onChangeFilter', {
        keyword: this.searchValue,
        payment_status: this.payment_status,
        to_date:
          this.to_date && this.from_date
            ? window.moment(this.to_date).endOf('day').unix()
            : undefined,
        from_date: this.from_date
          ? window.moment(this.from_date).startOf('day').unix()
          : undefined
      })
    }, 500),
    handleMapFilter () {
      if (isEmpty(this.$route.query)) return
      if (this.$route.query.keyword) {
        this.searchValue = this.$route.query.keyword
      }
      if (this.$route.query.payment_status) {
        this.payment_status = Number(this.$route.query.payment_status)
      }

      if (this.$route.query.from_date) {
        this.from_date = window
          .moment(Number(this.$route.query.from_date) * 1000)
          .format('YYYY-MM-DD HH:mm')
      }
      if (this.$route.query.to_date) {
        this.to_date = window
          .moment(Number(this.$route.query.to_date) * 1000)
          .format('YYYY-MM-DD HH:mm')
      }

      this.handleDebounce()
    }
  }
}
</script>
  
  <style lang="scss" scoped>
</style>