<template>
  <div>
    <el-dialog
      title="Xác nhận thanh toán"
      :visible.sync="isOpen"
      width="60%"
      :before-close="handleClose"
    >
      <template slot="title">
        <div class="fs-20 font-bold uppercase">
          {{ $t("Xác nhận thanh toán") }}
        </div>
      </template>
      <div class="text-black">
        <div>
          <div class="mb-1 fs-16">{{ $t("Phương thức thanh toán") }}</div>
          <PaymentMethod
            :paymentMethodProps="paymentMethod"
            @onChangeMethod="handleChangeMethod"
          />
        </div>
        <div class="flex justify-between items-center mt-2">
          <div class="font-bold fs-18">{{ $t("Tổng tiền:") }}</div>
          <div class="fs-18">{{ totalMoney.label }}</div>
        </div>
        <div class="mt-2 flex justify-between">
          <strong class="font-bold fs-18">{{ $t("Bằng chữ: ") }}</strong>
          <span class="cs-text inline-block ml-2 fs-18 justify-between">
            <p>{{ appUtils.numberToWords(totalMoney.value || 0) }}</p>
          </span>
        </div>

        <div
          class="flex flex-col md:flex-row items-center gap-2 justify-end mt-4"
        >
          <el-button plain type="primary" @click="handleClose">{{
            $t("Huỷ")
          }}</el-button>
          <el-button type="primary" @click="handleSubmit">{{
            $t("Thanh toán")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PaymentMethod from '@/components/PaymentMethod/index.vue'
import appUtils from '@/utils/appUtils'

export default {
  name: 'ModalPaymentReceipt',
  components: { PaymentMethod },
  data () {
    return {
      isOpen: false,
      paymentMethod: 1,
      receiptSelected: [],
      totalMoney: {},
      appUtils
    }
  },
  methods: {
    handleClose () {
      this.isOpen = false
    },
    handleOpen (data, totalAmount) {
      this.totalMoney = totalAmount || {}
      this.receiptSelected = data || []
      this.isOpen = true
    },
    async handleSubmit () {
      const r = await this.$confirm(
        'Bạn có chắc chắn muốn thanh toán không?',
        'Xác nhận thanh toán',
        {
          dangerouslyUseHTMLString: true,
          cancelButtonText: this.$t('lbl_cancel'),
          confirmButtonText: this.$t('lbl_confirm')
        }
      ).catch(() => {})

      if (!r) return

      this.requestPaymentMultipleSlips()
    },
    handleChangeMethod (value) {
      this.paymentMethod = value || 1
    },
    async requestPaymentMultipleSlips () {
      try {
        const params = {
          service_transaction_slips: this.receiptSelected?.map(
            (item) => item?.id
          ),
          payment_method: this.paymentMethod,
          total_money: this.totalMoney.value,
          return_url: `${window.location.origin}/#/payment-response-multiples`
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .requestPaymentMultipleSlips(params)

        if (response.data?.url) {
          window.open(response.data?.url, '_blank')
          return
        }

        if (response.status === 200) {
          this.$toast.open({
            message: this.$t('Thanh toán thành công'),
            type: 'success'
          })
          this.handleClose()
          this.$emit('onSuccess')
        }

        console.log(response)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-text {
  text-transform: lowercase;

  p::first-letter {
    text-transform: capitalize;
  }
}
</style>